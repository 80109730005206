<template>
  <div>
    <div v-if="isLoading" class="spinner-wrapper bg-white">
      <lottie-animation
          :width=300
          :height=300
          path="animation/freta-loading.json"
        />
    </div>
    
    <div id="terms" :style="cssProps">
      <div class="container" style="">
        <div class="row">
          <div
            class="col-12 col-xxl-8 offset-xxl-2 p-0"
          >
            <div class="col-md-12 p-0">
              <div class="cta row align-items-center">
                <div class="col-lg-9 col-12">
                  <h2>
                    Confira seu pedido, <br />
                    Aceite os termos e pronto!
                  </h2>
                </div>
              </div>

            </div>

            <div class="col-md-12" v-if="this.checkoutError">
              <div class="alert alert-danger" role="alert">
                {{this.checkoutError}}
              </div>
            </div>

            <div class="col-md-12 card py-5" v-if="order">

              <div class="col-12 p-0">
                <order-summary :order="order" />
              </div>

              <div class="f-card payment-order-details text-center">
                <h3 class="text-center">Termos do contrato</h3>
                <div class="mt-2">
                  <p class="mb-0">Você recebeu um email com um código para confirmar sua identidade</p>
                  <p>Insira o código abaixo e aceite os termos para finalizar sua contratação.</p>
                  <input 
                    type="tel" 
                    name="terms_code mb-3" 
                    class="terms_code"
                    v-model="terms_code"
                    maxlength="8"
                    v-on:keyup="checkTermsCode()"
                    id="">

                  <div class="checkbox">
                    <label class="checkbox-wrapper">
                      <input
                      type="checkbox"
                      name="agree-terms"
                      v-model="agree_terms"
                      id="agree-driver-expenses"
                      ref="agree_terms"
                      v-on:change="checkTermsIsAccepted()"
                      class="checkbox-input" />
                      <span class="checkbox-tile">
                      
                        <span class="checkbox-label"><a
                      href="javascript:void(0)"
                      @click="showModal('terms-of-use')"
                      > LI E ACEITO OS TERMOS, CONDIÇÕES DE USO E POLITICA DE PRIVACIDADE</a
                    ></span>
                      </span>
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="checkbox-wrapper">
                      <input
                      type="checkbox"
                      name="agree-terms"
                      v-model="agree_service_terms"
                      id="agree-driver-expenses"
                      ref="agree_service_terms"
                      v-on:change="checkTermsIsAccepted()"
                      class="checkbox-input" />
                      <span class="checkbox-tile">
                      
                        <span class="checkbox-label"><a
                      href="javascript:void(0)"
                      @click="showModal('terms-of-service')"
                      > LI E ACEITO AS CONDIÇÕES DO CONTRATO DE PRESTAÇÃO DE SERVIÇO.</a
                    ></span>
                      </span>
                    </label>
                  </div>
                  
                </div>

                <a
                  @click="acceptTerms()"
                  :class="[true ? 'btn p-3 mt-5 w-100 btn btn-primary btn-checkout' : '', this.validateComplete ? '' : 'disabled']"
                  >FINALIZAR MINHA RESERVA</a
                >

              </div>


              <b-modal
                id="terms-of-use"
                ref="terms-of-use"
                title="Termos de Uso"
              >
                <div v-html="this.$store.state.companyDetails.terms"></div>
                <template v-slot:modal-ok><button class="modalBtn" @click="termsAgree()">Aceitar</button></template>
              </b-modal>

              <b-modal
                id="terms-of-service"
                ref="terms-of-service"
                title="Termos de Prestação de Serviço"
              >
              <div v-html="this.$store.state.companyDetails.service_terms"></div>
                <template v-slot:modal-ok><button class="modalBtn" @click="termsServiceAgree()">Aceitar</button></template>
              </b-modal>

           
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

import OrderPaymentDetails from '@/components/general/OrderPaymentDetails.vue'
import OrderSummary from '@/components/order/OrderSummary.vue'
import OrderTripItem from '@/components/my-account/OrderTripItem.vue'
import TripItem from '@/components/general/TripItem.vue'

export default {
  name: 'Terms',
  components: { OrderTripItem,TripItem, OrderSummary, OrderPaymentDetails, LottieAnimation },
  metaInfo() {
    return {
      titleTemplate: '%s - Aceitar Termos',
    }
  },
  data () {
    return {
      checkoutError: '',
      order: null,
      checkout: null,
      validateComplete: false,
      isLoading: false,
      cc_errors: {},
      agree_terms: false,
      agree_service_terms: false,
      hasValidCode: false,
      terms_code: '',
      checkingTerms: false
    }
  },
  methods: {
    ...mapActions('auth', ['sendLoginRequest']),
    ...mapActions('auth', ['sendRegisterRequest']),
    ...mapActions('cart', ['getInstallments']),
    ...mapActions('cart', ['getBankSlipInstallments']),
    ...mapActions('cart', ['createOrder']),
    ...mapActions('cart', ['clearCart']),
   
    async acceptTerms() {
      
      if(!this.agree_terms || !this.agree_service_terms || !this.hasValidCode){
        this.$swal('Oops...', 'Você precisa aceitar os termos antes de continuar', 'error')
        return
      }

      await axios
        .post(process.env.API_URL + 'user/order/accept-terms', {
          order_id: this.order.id,
          terms_code: this.terms_code,
          customer_id: this.$store.state.auth.userData.id,
        })
        .then((response) => {
          if(response.status == 200) {
            this.$swal({
              title: 'Sucesso!',
              text: 'Reserva concluida com sucesso',
              icon: 'success',
              timer: 2000,
              confirmButtonText: 'Ok'
            }).then(() => {
              this.$router.push({ path: '/pedidos/' + this.order.id })
            })
          }else{
            this.$swal('Oops...', response.data.message, 'error')
          }
        })
        .catch(() => {})

    },
    async checkTermsCode() {
      if(this.checkingTerms || this.hasValidCode) return
      this.checkingTerms = true

      if(this.terms_code.length < 8) {
        this.hasValidCode = false
        this.checkingTerms = false
        this.checkTermsIsAccepted()
        return
      }
      await axios
        .post(process.env.API_URL + 'user/order/check-terms-code', {
          order_id: this.order.id,
          terms_code: this.terms_code,
        })
        .then((response) => {
          if(response.status == 200) {
            this.hasValidCode = true
            this.checkingTerms = false
            this.checkTermsIsAccepted()
          }else{
            this.$swal('Oops...', response.data.message, 'error')
          }
        })
        .catch(() => {})
    },
    termsAgree () {
      this.agree_terms = true
    },
    termsServiceAgree () {
      this.agree_service_terms = true
    },
    checkTermsIsAccepted() {
      if(this.terms_code.length > 0 && !this.hasValidCode){
        this.checkTermsCode();
      }
      if (this.agree_terms && this.agree_service_terms && this.hasValidCode) {
        this.validateComplete = true
      } else {
        this.validateComplete = false
      }
    },
    showModal: function (id) {
      this.$refs[id].show()
    },
    showLogin () {
      this.showLoginModal = true
    },
    closeModal () {
      this.showLoginModal = false
    },
    async getServiceTerms (total, items, customer) {
      await axios
        .post(process.env.API_URL + 'cart/terms/get-terms', {
          total: total,
          items: items,
          customer: customer
        })
        .then((response) => {
          this.$store.commit('updateServiceTerms', response.data)
        })
        .catch(() => {})
    },
   
  },
  computed: {
    ...mapGetters(['errors']),
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    },
  },
  beforeMount () {
  },
  created () {
    this.$store.commit('SET_LOADING_STATE', { value: true })
    /* eslint-disable no-new */
    new Promise((resolve, reject) => {
      axios.get(process.env.API_URL + 'user/orders/' + this.$route.params.id).then(async (r) => {
        var order = r.data.data;
        if(
          order.customer_id != this.$store.state.auth.userData.id ||
          order.acceptedTerms == 1
          ) {
          this.$router.push({ path: '/minha-conta/reservas' })
          return
        }
        this.order = order
        this.isLoading = false
        this.$store.commit('SET_LOADING_STATE', { value: false })
      })
    })

    new Promise((resolve, reject) => {
      axios.post(process.env.API_URL + 'services/generate-contract', {
        order_id: this.$route.params.id,
        return_html: "1"
      }).then((response) => {
        this.$store.commit('updateServiceTerms', response.data)
      })
    })
    
  },
  async mounted () {
    
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/pages/terms.scss";
  @import "../assets/scss/components/_vehicle-card.scss";

  #terms-of-use {
    display: block;
    padding-left: 15px;

    .modal-body {
      background: white!important;
      margin: 0 20px;
      border-radius: 10px;
      z-index: 1;
    }
    
  }
  #terms-of-service h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  #terms-of-service table tr td:first-child {
    width: 30%;
  }
  #terms-of-service header h5 {
    width: 100%;
    text-align: center;
    padding-left: 25px;
  }
  #terms-of-service  {
    table {
        width: 100%;
        border: 1px solid rgb(165, 165, 165);
      th {
        padding: 5px;
        width: 50%;
        text-align: left;
        border: 1px solid rgb(165, 165, 165);
      }
      td {
        padding: 5px;
        width: 50%;
        border: 1px solid rgb(165, 165, 165);
      }
    }
  }

  .configurations-wrapper {
    span {
      color: var(--default-color);
      border-color: var(--default-color);
    }
  }

  .block-address-form {
    width: 100%;
    height: 100%;
    background: #e0e0e082;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .empty-list {
    min-height: calc(60vh + 6px);
    h5 {
      font-weight: 400;
    }
  }

  .btn-primary{
    background-color: var(--default-color);
    border-color: var(--default-color);
    &.disabled {
      opacity: .6;
      background-color: #8b8b8b;
      border-color: #8b8b8b;
      cursor: not-allowed;
    }
  }

  #terms{
    .cta {
      h2 {
        color: white;
        font-size: 1.4rem;
      }

      .btn-outline-white {
        color: white;
        border-color: white;
        border: 1px solid;
        padding: 5px 15px;
        &:hover {
          color: var(--default-color);
          background-color: white;
        }
      }
      background-color: var(--default-color)
    }
  }

  .block-ui {
    filter: grayscale(1);
    opacity: .6;
    cursor: not-allowed;
  }

  .block-payment {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #cbcbcb66;
    z-index: 9;
    filter: grayscale(1);
  }

  .error-fields {

    margin-top: 10px;
    margin-bottom: 40px;

    .required-field {
      background: #ededed;
      margin-right: 10px;
      padding: 5px 10px;
    }
  }
  .payment-data{
    p {
      svg {
        color: var(--default-color);
        font-size: 32px;
        margin-right: 20px;
      }
    }

    .payment-methods {

      @media screen and (min-width: 1024px) {
        .payment:first-child{
          padding-right: 0;
        }
        .payment:not(:first-child) {
          padding-left:2px;
          padding-right:0px;
        }

      }
      .box {
        background-color: #ffffff;
        border-radius: 0.25rem;
        font-size: 14px;
        color: #8b8b8b;
        height: 60px;
        width: 100%;
        border: 1px solid #e8e8e8;
        padding: 10px 20px;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px;
        z-index: 0;
        position: relative;

        &:hover {
          border-color: var(--default-color);
          background-color: rgb(255, 255, 255);
          color: var(--default-color);
          cursor: pointer;

        }

        &.active {
          border-color: var(--default-color);
          background-color: white;
          color: var(--default-color);
          z-index: 9;
          border-bottom: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          @media (max-width: 1024px) {
            border:1px solid;
          }
        }

        &.inactive {
          opacity: .5;
          cursor: not-allowed
        }
      }
    }

    .payment-information {
      .info {
        border: 1px solid;
        border-color: var(--default-color);
        padding: 30px 20px;
        margin-top: 0;
        font-size: 14px;
        border-radius: 5px;
        border-top-left-radius: 0;
      }
    }
  }

  .address-data {
    padding: 10px 30px;
  }

  .checkout-total {
    padding: 20px 60px;
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 18px;
    }

    @media ( max-width: 980px) {
      padding: 20px 20px;

      .col-md-7 {
        padding: 0;
      }
    }
  }
  .card.payment-data{
    padding: 30px 40px !important;
  }

  @media(max-width: 1024px) {
    .card.payment-data{
      padding: 30px 15px !important;
    }
  }

 .btn-coupon {
    background-color: white;
    border: 1px solid;
    border-color: var(--default-color);
    border-radius: 4px;
    font-weight: 400;
    display: block;
    width: 100%;
    color:  var(--default-color);
    padding: 10px!important;

    svg {
      path {
        fill: var(--default-color);
      }
    }

    &:hover {
        color: #fff !important;
        background: var(--default-color);;
    }
  }

  .btn-checkout {
    background-color: var(--default-color);
    border-color: var(--default-color);
    color: white;

    &:hover {
      opacity: .7
    }
  }

  .checkout-login {

    .btn {
      margin-bottom: 20px;
    }

    h4 {
      text-align: center;
      padding: 20px;
      color: #565656;
    }

    a {
      width: fit-content;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0 auto;
      border: 1px solid var(--default-color);
      color: var(--default-color);
      margin-bottom: 20px;

      &:hover {
        background-color: var(--default-color);
        color: white!important;
      }
    }
  }

  .cupom-details {
    padding: 10px 0px;
    .content {
      .name {
        font-weight: 500;
        font-size: 22px;
        span {
        color: black;
        font-weight: 500;
        font-size: 14px;
        }
      }
      .discount {
        text-align: right;
        font-size: 22px;
        span {
        color: black;
        font-weight: 500;
        font-size: 14px;
        }
      }
    }
  }
  .modalBtn{
    background: transparent;
    border: none;
  }
  .modalBtn:hover{
    color: #fff;
  }
  @media screen and (max-width:968px){
    #terms-of-use {
      padding-left: 0 !important;
    }
  }

</style>
